import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Card, Divider, List, Typography, TextField, Button } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
/* import { HashLink as Link } from 'react-router-hash-link';
import { Scroll } from '../Scroll'; // Adjust the import path accordingly */
import logo from '../ASSETS/logoi.png';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Scroll } from '../Scroll';
import { useTranslation } from 'react-i18next';
import { Instagram } from '@mui/icons-material';
const navItem = [
  { label: 'About us', path: '/' },
  { label: 'Consulting', path: '/consulting' },
  { label: 'Coaching', path: '/couching' },
  { label: 'Mentoring', path: '/mentoring' },
  { label: 'Contact', path: '/contact' }
];

export default function Footer() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';
  return (
    <Box id='contact' sx={{ width: '100%', margin: '4rem auto', marginBottom: '0rem',direction:isRTL?'rtl':'ltr'  }}>
      <Card
        sx={{
          maxWidth: 1500,
          margin: '4rem auto',
          marginBottom: '0rem',
          borderRadius: '30px',
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          backgroundColor: '#E8E7E5',
          padding: '2rem',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Box sx={{marginLeft:{xs:'6rem',md:'3rem'}}}>
            <img src={logo} alt="" style={{  width: '130px', height: '140px', color: 'white', marginTop: '0.5rem',marginLeft:{xs:'5rem',md:'3rem'},marginRight:  isRTL ? '6rem' : '0rem'  }} /></Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0rem auto',
                textAlign: 'center',
              }}
            >
              <List>
                {navItem.map((item) => (
                  <Button
                    key={item.label}
                    component={Link}
                    smooth
                    to={item.path}
                    scroll={el => Scroll(el)}
                    sx={{ color: '#2f2f2f', textDecoration: 'underline', marginBottom: '0.5rem','&:hover':{backgroundColor:'transparent'} ,fontFamily: isRTL?'El Messiri':  'Raleway',}}
                  >
                    {t(item.label)}
                  </Button>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                justifyContent: 'center', // Center icons horizontally
                alignItems: 'center',
                textAlign: 'center',
                marginTop: { xs: '1rem', md: '0.5rem' }, 
                marginRight: { xs: '1.5rem', md: '0.5rem' },// Adjust top margin for smaller screens
              }}
            >
            <Link style={{textDecoration:'none'}} to='https://www.instagram.com/the_leadership_bureau?igsh=cjE1dTJmeHc3aTdk'> <Instagram sx={{ fontSize: '30px', color: '#2f2f2f', marginLeft: '1rem' }} /></Link> 
            <Link style={{textDecoration:'none'}} to=''>  <FacebookIcon sx={{ fontSize: '30px', color: '#2f2f2f', marginLeft: '1rem' }} /></Link> 
            <Link style={{textDecoration:'none'}}to=''> <XIcon sx={{ fontSize: '30px', color: '#2f2f2f', marginLeft: '1rem' }} /></Link> 
            </Box> 
          </Grid>
        </Grid>
        <Grid container spacing={2} columns={{ xs: 12, md: 12 }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                justifyContent: 'left',
                alignItems: 'left',
                margin: '0rem auto',
                textAlign:  isRTL?'right':'left',
                marginTop: '0rem',
                marginLeft:'3rem',
                marginRight: isRTL?'4rem':'0rem'
              }}
            >
              <Typography
                sx={{
                  fontSize: '25px',
                  textDecoration: 'underline',
                  fontFamily: isRTL?'El Messiri':  'Raleway',
                  fontWeight: 700,
                  borderRadius: '10px',
                  color: '#2f2f2f',
                  marginTop: '2rem',
                  marginBottom: '1rem',
                  marginLeft: { xs: '2rem', md: '0.5rem' },
                 
                }}
              >
                {t("Contact us:")}
              </Typography>
              <Link style={{textDecoration:'none'}} to='mailto:connect@bureauconnect.net'>
              <Typography
                sx={{
                  color: '#2f2f2f',
                  fontSize: '15px',
                  fontFamily: isRTL?'El Messiri':  'Raleway',
                  marginTop: '0.5rem',
                  marginLeft: { xs: '2rem', md: '0.5rem' },
                  direction:isRTL?'rtl':'ltr'
                }}
              >
                {t("Email:")} 

                connect@bureauconnect.net   
                           </Typography></Link>
                           <Link style={{textDecoration:'none'}} to='tel:555-567-8901'>
              <Typography
                sx={{
                  color: '#2f2f2f',
                  fontSize: '15px',
                  fontFamily: isRTL?'El Messiri':  'Raleway',
                  marginTop: '0.5rem',
                  marginLeft: { xs: '2rem', md: '0.5rem' }
                }}
              >
                {t("Phone:")} 0793 818 696
              </Typography></Link>
              <Link style={{textDecoration:'none'}} to='/contact'>
              <Typography
                sx={{
                  color: '#2f2f2f',
                  fontSize: '15px',
                  fontFamily: 'Raleway',
                  marginTop: '0.5rem',
                  marginLeft: { xs: '2rem', md: '0.5rem' }
                }}
              >
               Avenue House Plaza , Mezzanine , Kenyatta Avenue, Nairobi , Kenya
              </Typography></Link>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
          <Card
      sx={{
        maxWidth: 600,
        margin: '4rem auto',
        borderRadius: '10px',
        border:'1px solid #EEECEB',
        backgroundColor: '#EEECEB', // Light background
        display: 'flex',
        padding: '2rem',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for better visibility
      }}
    >
      <Grid
        container
        spacing={2}
        columns={12}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={6}>
          <TextField
            placeholder= "Email"
            variant="outlined"
            fullWidth
            sx={{
              borderRadius: '10px',
              border: '1px solid #ddd',
              '& input': {
                color: '#333',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderRadius: '10px',
                },
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#bbb',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#888',
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            sx={{
              fontFamily: isRTL?'El Messiri':  'Raleway',
              fontSize: { xs: '12px', md: '15px' },
              width: '100%',
              backgroundColor: '#FFCB74',
              borderRadius: '10px',
              color: 'black',
              padding: { xs: '0.8rem', md: '1rem' },
              fontWeight: '400',
              border: '1px solid #FFCB74',
              '&:hover': {
                backgroundColor: '#FFCB74',
                border: '1px solid #FFCB74',
              },
            }}
          >
            {t("Subscribe to News")}
          </Button>
        </Grid>
      </Grid>
    </Card>
          </Grid>
          <Divider sx={{ backgroundColor: '#2f2f2f', width: '100%' }} />
          <Typography
            sx={{
              color: '#2f2f2f',
              paddingRight: '2rem',
              marginTop: '1rem',
              fontFamily: isRTL?'El Messiri':  'Raleway',
              marginLeft: { xs: '2rem', md: '0.5rem' }
            }}
          >
            {t("© 2022 The Leadership Bureau. All Rights Reserved.")}
          </Typography>
          <Typography
            sx={{
              color: '#2f2f2f',
              marginTop: '1rem',
              fontFamily: isRTL?'El Messiri':  'Raleway',
              textDecoration: 'underline',
              marginLeft: { xs: '3rem', md: '0.5rem' }
            }}
          >
            {t("Privacy Policy")}
          </Typography>
        </Grid>
      </Card>
    </Box>
  );
}
